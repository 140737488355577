import http from '../axios/http'

// 请求模块
const apiModel = '/common'

// 回收站分页
export function recyclePage(params) {
  return http.get(apiModel + '/recycle/page', params)
}

// 清除回收站
export function recycleClear(params) {
  return http.post(apiModel + '/recycle/clear', params)
}

// 还原回收站
export function recycleRest(params) {
  return http.post(apiModel + '/recycle/rest', params)
}
