<template>
  <div class="resource-recycle">
    <el-container>
      <el-header class="head-search">
        <el-row :gutter="24" style="width: 100%; text-align: left">
          <el-col :span="12">
            <el-select v-model="recycleType" @change="() => queryPage()">
              <el-option key="notes" label="笔 记" value="notes" />
              <el-option key="manuscript" label="草 稿" value="manuscript" />
              <el-option key="catalog" label="目 录" value="catalog" />
              <el-option key="tag" label="标 签" value="tag" />
              <el-option key="image" label="图 片" value="image" />
            </el-select>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <el-button type="primary" @click="restSelect">
              <icon icon-name="Promotion" icon-size="20" icon-title="还原选项"></icon>
            </el-button>
            <el-button type="danger" @click="clearSelect">
              <icon icon-name="Delete" icon-size="20" icon-title="清理选项"></icon>
            </el-button>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-table :data="tableData" @selection-change="selectionChange">
          <el-table-column type="selection" />
          <el-table-column label="时 间" header-align="center" width="250">
            <template #default="scope">
              <el-icon>
                <component :is="Clock"></component>
              </el-icon>
              <span style="margin-left: 10px">
                {{ scope.row.finalTime }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="target"
            label="标 题"
            :show-overflow-tooltip="true"
            header-align="center"
            align="left"
          />
          <el-table-column fixed="right" width="250" label="操 作" align="center">
            <template #default="scope">
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                :icon="InfoFilled"
                icon-color="red"
                title="确定还原当前资源选项?"
                @confirm="rest([scope.row.serial])"
              >
                <template #reference>
                  <el-button size="small" type="primary">
                    <icon icon-name="Promotion" icon-size="20" icon-title="还 原"></icon>
                  </el-button>
                </template>
              </el-popconfirm>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                :icon="InfoFilled"
                icon-color="red"
                title="确定清理当前资源选项?"
                @confirm="clear([scope.row.serial])"
              >
                <template #reference>
                  <el-button size="small" type="danger">
                    <icon icon-name="Delete" icon-size="20" icon-title="清 理"></icon>
                  </el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer>
        <page
          @changePage="queryPage"
          :pageSize="page.size"
          :currentPage="page.current"
          :total="page.total"
        ></page>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { Clock, InfoFilled } from '@element-plus/icons-vue'
import icon from '@/components/icon'
import page from '@/components/page'
import { recycleClear, recyclePage, recycleRest } from '@/api/CommonApi'

export default {
  name: 'ResourceRecycle',
  components: {
    icon,
    page
  },
  setup() {
    return {
      Clock,
      InfoFilled
    }
  },
  data() {
    return {
      recycleType: 'notes',
      page: {
        size: 13,
        current: 1,
        total: 0
      },
      tableData: [],
      selectChange: []
    }
  },
  methods: {
    queryPage(current) {
      recyclePage({
        recycleType: this.recycleType,
        pageNum: typeof current !== 'undefined' ? current : 1,
        pageSize: this.page.size
      }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.rows
          this.page.size = res.data.pageSize
          this.page.current = res.data.currentPage
          this.page.total = res.data.total
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    },
    selectionChange(array) {
      this.selectChange = array.map((e) => e.serial)
    },
    clear(arrays) {
      if (arrays == null || arrays.length === 0) {
        this.$message({ message: '请先选择要操作的对象！', type: 'warning' })
        return
      }
      recycleClear({
        recycleType: this.recycleType,
        target: arrays
      }).then((res) => {
        if (res.code === 200) {
          this.queryPage()
          this.$message({ message: '清理完毕', type: 'success' })
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    },
    rest(arrays) {
      if (arrays == null || arrays.length === 0) {
        this.$message({ message: '请先选择要操作的对象！', type: 'warning' })
        return
      }
      recycleRest({
        recycleType: this.recycleType,
        target: arrays
      }).then((res) => {
        if (res.code === 200) {
          this.queryPage()
          this.$message({ message: '还原完毕', type: 'success' })
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    },
    clearSelect() {
      this.clear(this.selectChange)
    },
    restSelect() {
      this.rest(this.selectChange)
    }
  },
  mounted() {
    this.queryPage()
  }
}
</script>

<style scoped>
.resource-recycle {
  width: 100%;
  min-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 5px;
}

.head-search {
  width: 100%;
  min-width: 700px;
  display: flex;
  align-items: center;
}

.resource-recycle >>> .el-main {
  min-height: 690px;
}
</style>
